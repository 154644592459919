import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import usePageview from '../../hooks/usePageview';
import Title from '../../components/Title';
import InShopDetails from '../../components/InShopDetails';

const CarServices = () => {
	usePageview('/carservices');

	const fbEvent = () => {
		return ReactPixel.fbq('track', 'Lead');
	}

	const MetaConversionsAPI = async () => {
		const proxyUrl = 'https://ancient-bastion-25891.herokuapp.com/'
		const apiUrl = 'https://pete.tines.com/webhook/effortless/api?type=meta-conversions&event=lead';

		let res = await fetch(proxyUrl + apiUrl, {
			method: 'POST'
		});
		console.log(res)
		return res.status
	};

	const gaEvent = () => {
		return ReactGA.ga('send', 'event', 'link', 'click', `Make An Appointment Link`)
	};

	const nextdoorEvent = () => {
		const script = document.createElement('script');
		const noScript = document.createElement('noscript');
		script.async = true;
		noScript.async = true;
		script.innerText = `(function(win, doc, sdk_url){
			if(win.ndp) return;
			var tr=win.ndp=function(){
			tr.handleRequest? tr.handleRequest.apply(tr, arguments):tr.queue.push(arguments);
		  };
			tr.queue = [];
			var s='script';
			var new_script_section=doc.createElement(s);
			new_script_section.async=!0;
			new_script_section.src=sdk_url;
			var insert_pos=doc.getElementsByTagName(s)[0];
			insert_pos.parentNode.insertBefore(new_script_section, insert_pos);
		  })(window, document, 'https://ads.nextdoor.com/public/pixel/ndp.js');
		 
			ndp('init','9ca2a6e0-d93e-4e18-a1e4-0e07a0929250', {});
			ndp('track','CONVERSION');`
		noScript.innerHTML = `<img height="1" width="1" style="display:none"
		src="https://flask.nextdoor.com/pixel?pid=9ca2a6e0-d93e-4e18-a1e4-0e07a0929250&ev=CONVERSION&noscript=1"/>`
		document.head.appendChild(script);
		document.head.appendChild(noScript);
	}

	return (
		<>
			<div className='container'>
				<Title heading={'Before You Book:'} alert={true} />
				<InShopDetails  />
				<a href="https://effortlessmobileautoservices.fieldd.co/">
					<button className='btn btn-warning' onClick={() => {
								MetaConversionsAPI();
								fbEvent();
								gaEvent();
								nextdoorEvent();
							}}>
						See Prices / Make an Appointment
					</button>
				</a>
			</div>
		</>
	);
};

/*
import ServiceCard from '../../components/ServiceCard';
import SpecialtyServices from '../../components/SpecialtyServices';
import ALaCarteServices from '../../components/ALaCarteServices';
import AddOnServices from '../../components/AddOnServices';

				<Title heading='Pricing' alert={true} />
					<div className='row'>
					<ServiceCard
						colStyle='col-sm-4 col-md-12 col-lg-4'
						title='Basic Interior'
						bullets={[
							'Surface dusting and UV protection',
							'Air vent dusting',
							'Interior glass clean',
							'Interior vacuum',
							'Sun roof and gauge cluster polish',
							'Door jamb wipe down',
							'Fragrance application',
						]}
						pricing={[
							{
								vehicle: 'Coupe: $69',
							},
							{
								vehicle: 'Sedan: $69',
							},
							{
								vehicle: 'Light Truck: $79',
							},
							{
								vehicle: 'Compact SUV: $79',
							},
							{
								vehicle: 'Full Size Truck: $89',
							},
							{
								vehicle: 'Full Size SUV: $109',
							},
						]}
						url={"https://effortlessmobileautoservices.fieldd.co/"}
					/>
					<ServiceCard
						colStyle='col-sm-4 col-md-12 col-lg-4'
						title='Basic Exterior'
						bullets={[
							'Liquid carnauba wax',
							'Exterior glass clean',
							'Steam cleaned wheels',
							'Steam wash',
							'Tire dress',
						]}
						pricing={[
							{
								vehicle: 'Coupe: $69',
							},
							{
								vehicle: 'Sedan: $69',
							},
							{
								vehicle: 'Light Truck: $79',
							},
							{
								vehicle: 'Compact SUV: $79',
							},
							{
								vehicle: 'Full Size Truck: $99',
							},
							{
								vehicle: 'Full Size SUV: $109',
							},
						]}
						url={"https://effortlessmobileautoservices.fieldd.co/"}
					/>
					<ServiceCard
						colStyle='col-sm-4 col-md-12 col-lg-4'
						title='Basic Full Car'
						bullets={[
							'All Basic Interior Services',
							'All Basic Exterior Services',
							'Leaves your vehicle fresh and clean inside and out',
						]}
						pricing={[
							{
								vehicle: 'Coupe: $129',
							},
							{
								vehicle: 'Sedan: $129',
							},
							{
								vehicle: 'Light Truck: $149',
							},
							{
								vehicle: 'Compact SUV: $149',
							},
							{
								vehicle: 'Full Size Truck: $179',
							},
							{
								vehicle: 'Full Size SUV: $209',
							},
						]}
						url={"https://effortlessmobileautoservices.fieldd.co/"}
					/>
				</div>
				<div className='row'>
					<ServiceCard
						colStyle='col-sm-4 col-md-12 col-lg-4'
						title='Complete Interior'
						hq={true}
						bold={'*** INCLUDES LEATHER CLEAN AND CONDITION ***'}
						bullets={[
							'Interior steam clean and disinfect',
							'Air vent dusting',
							'Surface dusting and UV protection',
							'Glove box and center console clean (if empty)',
							'Floor mat steam clean, shampoo, and vacuum',
							'Interior and trunk vacuum',
							'Fabric steam clean and shampoo',
							'Carpet steam clean and shampoo',
							'Headliner spot clean',
							'Door jamb steam clean',
							'Interior glass clean',
							'Sun roof and gauge cluster polish',
							'Fragrance application',
							'Windshield washer fluid top off',
						]}
						pricing={[
							{
								vehicle: 'Coupe: $149',
							},
							{
								vehicle: 'Sedan: $149',
							},
							{
								vehicle: 'Light Truck: $169',
							},
							{
								vehicle: 'Compact SUV: $169',
							},
							{
								vehicle: 'Full Size Truck: $179',
							},
							{
								vehicle: 'Full Size SUV: $209',
							},
						]}
						url={"https://effortlessmobileautoservices.fieldd.co/"}
					/>
					<ServiceCard
						colStyle='col-sm-4 col-md-12 col-lg-4'
						title='Complete Exterior'
						hq={true}
						bullets={[
							'Wheel deep clean and seal',
							'Paint seal with high-quality sealant',
							'Exterior glass clean',
							'Trim dress and UV treatment',
							'Steam wash',
							'Tire dress',
							'Windshield washer fluid top off',
						]}
						pricing={[
							{
								vehicle: 'Coupe: $129',
							},
							{
								vehicle: 'Sedan: $129',
							},
							{
								vehicle: 'Light Truck: $139',
							},
							{
								vehicle: 'Compact SUV: $139',
							},
							{
								vehicle: 'Full Size Truck: $159',
							},
							{
								vehicle: 'Full Size SUV: $179',
							},
						]}
						url={"https://effortlessmobileautoservices.fieldd.co/"}
					/>
					<ServiceCard
						colStyle='col-sm-4 col-md-12 col-lg-4'
						title='Complete Full Car'
						hq={true}
						bold={'*** INCLUDES LEATHER CLEAN AND CONDITION ***'}
						bullets={[
							'All Complete Interior Services',
							'All Complete Exterior Services',
							'Leaves your vehicle looking deep-cleaned and protected, inside and out',
						]}
						pricing={[
							{
								vehicle: 'Coupe: $249',
							},
							{
								vehicle: 'Sedan: $249',
							},
							{
								vehicle: 'Light Truck: $279',
							},
							{
								vehicle: 'Compact SUV: $279',
							},
							{
								vehicle: 'Full Size Truck: $299',
							},
							{
								vehicle: 'Full Size SUV: $359',
							},
						]}
						url={"https://effortlessmobileautoservices.fieldd.co/"}
					/>
				</div>
				<Title
					heading='Add-On Services'
					alert={true}
					specialty={'(Purchase in addition to a detail service)'}
					id={'add-on-services'}
				/>
				<AddOnServices url={"https://effortlessmobileautoservices.fieldd.co/"} />
				<Title
					heading='A La Carte Services'
					alert={true}
					specialty={
						'(Purchase individually or pair with another service)'
					}
				/>
				<ALaCarteServices url={"https://effortlessmobileautoservices.fieldd.co/"}/>
				<Title heading='Specialty Services' alert={true} />
				<SpecialtyServices />
*/

export default CarServices;
