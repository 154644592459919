import React from 'react'
import Title from './Title';
import Description from './Description';
import ReactGA from 'react-ga';

const GiftCards = () => {
    const gaEvent = (serviceName) => {
		return ReactGA.event({
			category: 'click',
			action: 'link',
			label: `${serviceName}`,
		});
	};

  return (
    <div className='gift-cards'>
        <Title heading={'Effortless eGift cards are now available!'} />
        <Description
            text={
                "Show how much you care by getting them a gift you know they will truly appreciate!"
            }
            descStyle={'gift-cards'}
        />
        <a href="https://squareup.com/gift/FA7Q3E2F65HPM/order"><button className='btn btn-warning' onClick={() => {gaEvent('Gift Card')}}>Order Now</button></a>
    </div>	
  )
}
/*
        <Title heading={''} />
        <Description
            text={
                ""
            }
        />
/*

/*
        <Title heading={'Effortless eGift cards holiday promotion!'} />
        <Description
            text={
                "All gift cards are now ~ with promo code: ~"
            }
            bold={'10% off through the end of the year'}
            bold2={'HOLIDAYS25'}
            descStyle={'gift-cards'}
        />
*/

        /*
        <Title heading={'Black Friday (11/24) - Cyber Monday (11/27)'} />
        <Description
            text={
                "All gift cards are now ~ with promo code: ~"
            }
            bold={'25% off'}
            bold2={'BLACKFRIDAY'}
            descStyle={'gift-cards'}
        />
        <br />
        */

export default GiftCards;