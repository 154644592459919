import React, { useState } from 'react';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import CarServices from './Services/CarServices';
import TintingServices from './Services/TintingServices';
import FleetServices from './Services/FleetServices';
import Disclaimers from './Services/Disclaimers';
import GiftCards from '../components/GiftCards';

const pages = [
	{
		name: 'Detail Services',
		id: 'carservices',
		index: 0,
	},
	{
		name: 'Tinting Services',
		id: 'tintingservices',
		index: 1,
	},
	{
		name: 'Fleet Services',
		id: 'fleetservices',
		index: 2,
	},
	{
		name: 'Disclaimers',
		id: 'disclaimers',
		index: 3,
	},
];

const Services = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const Service = ({ match }) => {
		const service = pages.find(({ id }) => id === match.params.serviceId);
		if (`${service.id}` === 'carservices') {
			return <CarServices />;
		} else if (`${service.id}` === 'tintingservices') {
			setActiveIndex(1);
			return <TintingServices />;
		} else if (`${service.id}` === 'fleetservices') {
			return <FleetServices />;
		} else if (`${service.id}` === 'disclaimers') {
			return <Disclaimers />;
		}
	};
	/* Paint Services 
		import PaintServices from './Services/PaintServices';

		Add to pages array
		{
			name: 'Paint Services',
			id: 'paintservices',
			index: 1,
		},

		add to Service function
		else if (`${service.id}` === 'paintservices') {
			setActiveIndex(1);
			return <PaintServices />;
		}
	*/

	const onClick = (index, e) => {
		e.preventDefault();
		return setActiveIndex(index);
	};

	return (
		<>
			<GiftCards />
			<br />
			<nav id='servicesNav' aria-label='Services Micro Nav'>
				<ul className='pagination'>
					{pages.map(({ name, id, index }) => (
						<li
							key={id}
							index={index}
							onClick={(e) => onClick(index, e)}
							className={
								activeIndex === index ? 'page-item active' : 'page-item'
							}>
							<Link className='page-link' id={id} to={`/services/${id}`}>
								{name}
							</Link>
						</li>
					))}
				</ul>
			</nav>
			<Switch>
				<Route exact path={`/services/:serviceId`} component={Service} />
				<Redirect
					from='/services/tintingservices'
					to='/services/tintingservices'
				/>
				<Redirect from='/services' to='/services/carservices' />
			</Switch>
			<br />
		</>
	);
};

export default Services;