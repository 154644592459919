import React from 'react';
import usePageview from '../hooks/usePageview';
import Title from '../components/Title';
import Description from '../components/Description';
import AppointmentButton from '../components/AppointmentButton';
import Carousel from '../components/Carousel';
import PromoVideo from '../components/PromoVideo';
import HomePageServices from '../components/HomePageServices';
import ExpertiseBadge from '../components/ExpertiseBadge';
import GiftCards from '../components/GiftCards';
import one from '../images/carousel/mobile.jpg';
import two from '../images/carousel/in-shop-2.png'
import three from '../images/carousel/3.jpg';
import five from '../images/carousel/5.jpg';
import exotic from '../images/carousel/exotics.jpg';
import nine from '../images/carousel/9.jpg';
import headlights from '../images/carousel/headlights.png';
import ceramic from '../images/carousel/ceramic.jpg';

const carouselSlides = [
	{
		uri: { one },
		alt: 'Mobile Auto Detailing',
		title: 'We offer mobile AND in-shop detailing services!',
		caption:
			"We'll come to your home, office, or any other location that is convenient for you to offer interior, exterior, polishing, and engine bay detailing services that will make your vehicle shine! We'll also help you protect your vehicle from the harsh Arizona elements with a variety of high quality sealants, dressings, conditioners, and carnauba waxes.",
	},
	{
		uri: { two },
		alt: 'In-Shop Auto Detailing',
		title: 'In-Shop Services',
		caption:
			"In addition to the convenience offered by our mobile services, we also provide the option for you to bring your vehicle to our shop! All in-shop detail services are ~ and we typically have quicker availability in-shop if you need your vehicle detailed ASAP. Not interested in mobile service but unable to bring your vehicle in? Ask us about our vehicle pick-up/drop-off services!",
		bold: 'currently 15% off'
	},
	{
		uri: { three },
		alt: 'We perform auto detailing indoors and outdoors',
		title: 'We perform auto detailing indoors or outdoors!',
		caption:
			'We use Chief Steamer™ steam technology combined with a selection of high quality professional products to detail both the interior and exterior of your vehicle. Using steam instead of water creates very little runoff. This means we can detail in parking garages, personal garages, and showrooms in addition to any outdoor location.',
	},
	{
		uri: { ceramic },
		alt: 'Ceramic Coating Audi R8',
		title: 'Ceramic Coating',
		caption:
			'Protect your investment and give your vehicle that beautiful always-wet look with a ceramic coating! Our ceramic coatings offer 2+ years of excellent paint protection.',
	},
	{
		uri: { five },
		alt: 'Engine Bay',
		title: 'Does your engine bay need some love? We’ve got you covered!',
		caption:
			'With steam, your vehicle’s engine is exposed to less water during the detail process and therefore less risk of an electrical short. It also allows us to get into every nook and cranny for an extremely thorough cleaning.',
	},
	{
		uri: { headlights },
		alt: 'Headlight Restoration',
		title: 'Are your headlights foggy?',
		caption: 'We offer headlight restoration to clear them up!',
	},
	{
		uri: { exotic },
		alt: 'Exotics or Classic Cars',
		title: 'Do you own an exotic or classic car?',
		caption:
			'Now you’ve finally found a detailer you can trust. We’ve been caring for these gems for years, and treat each one as if it’s our own. We have the knowledge and experience to make sure exotics and classics are properly maintained and protected.',
	},
	{
		uri: { nine },
		alt: 'Motorcycle',
		title: 'We also detail motorcycles, golf carts, trikes, and more!',
		caption:
			'If it has wheels (and sometimes even if it doesn’t) we will detail it! Reach out via phone or email for a custom quote.',
	},
];

const Home = () => {
	usePageview('/homepage');

	let width = window.innerWidth;

	return (
		<>
			{ width < 992 && <AppointmentButton />}
			<Title heading={'Walk-ins welcome!'} />
			<Description 
				text={
					"We recommend calling ahead or scheduling online to ensure availability"
				}
				descStyle={'gift-cards'}
			/>
			<GiftCards />
			<br />
			{width >= 768 ? (
				<PromoVideo />
			) : (
				<Carousel slides={carouselSlides} />
			)}
			<div className='expertise-home'></div>
			<ExpertiseBadge />
			<HomePageServices />
		</>
	);
};

export default Home;

/*
	<GiftCards />
	<br /><br />
*/